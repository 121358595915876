import logoLight from "@/images/Logo.svg"
import logoDark from "@/images/logo2.png"
import logoFractal from "@/images/fractal.svg"
import appStoreFooter from "@/images/app-store.png"
import playStoreFooter from "@/images/play-store.png"
import sliderOne01 from "@/images/slider/img1_slider.png"
import sliderThree01 from "@/images/slider/3_1.jpg"
import sliderThree02 from "@/images/slider/3_2.jpg"
import slideAbout from "@/images/slider/slide-about.png"
import shape01 from "@/images/slider/shape_1.svg"
import shape02 from "@/images/slider/arrows.svg"
import blogImage1 from "@/images/blog/1.jpg"
import blogImage2 from "@/images/blog/2.jpg"
import blogImage3 from "@/images/blog/3.jpg"
import blogImage4 from "@/images/blog/4.jpg"
import blogImage5 from "@/images/blog/5.jpg"
import blogImage6 from "@/images/blog/6.jpg"
import blogImageTwo1 from "@/images/blog/14.jpg"
import blogImageTwo2 from "@/images/blog/15.jpg"
import blogS1 from "@/images/blog/11.jpg"
import blogS2 from "@/images/blog/12.jpg"
import blogS3 from "@/images/blog/13.jpg"
import commentImage1 from "@/images/blog/9.jpg"
import commentImage2 from "@/images/blog/10.jpg"
import portfolio01 from "@/images/portfolio/1.jpg"
import portfolio02 from "@/images/portfolio/2.jpg"
import portfolio03 from "@/images/portfolio/3.jpg"
import portfolio04 from "@/images/portfolio/4.jpg"
import portfolio05 from "@/images/portfolio/5.jpg"
import portfolio06 from "@/images/portfolio/6.jpg"
import portfolioD01 from "@/images/portfolio/7.jpg"
import portfolioD02 from "@/images/portfolio/8.jpg"
import serviceOne01 from "@/images/home_1/6.jpg"
import serviceOne02 from "@/images/home_1/7.jpg"
import serviceOne03 from "@/images/home_1/8.jpg"
import trustClient01 from "@/images/home_1/4.jpg"
import aboutOne01 from "@/images/about/1.jpg"
import aboutOne02 from "@/images/about/2.jpg"
import team01 from "@/images/team/1.jpg"
import team02 from "@/images/team/2.jpg"
import team03 from "@/images/team/3.jpg"
import team04 from "@/images/team/4.jpg"
import team05 from "@/images/team/5.jpg"
import video01 from "@/images/about/3.jpg"
import testimonial01 from "@/images/home_1/t1.jpg"
import testimonial02 from "@/images/home_1/t2.jpg"
import testimonial03 from "@/images/home_1/t3.jpg"
import featureTab01 from "@/images/home_1/c1.jpg"
import featureTab02 from "@/images/home_1/c2.jpg"
import featureTab03 from "@/images/home_1/c3.jpg"
import video02 from "@/images/video/video.png"
import aboutTwo01 from "@/images/home_1/2.jpg"
import aboutTwo02 from "@/images/home_1/1.jpg"
import featureTwo01 from "@/images/home_1/5.jpg"
import vertereLogo from "@/images/support/vertere.svg"
import fractalLogo from "@/images/support/fractal-2.svg"
import minLogo from "@/images/support/ministerio.svg"
import sapientiaLogo from "@/images/support/sapientia.svg"
import vitalisLogo from "@/images/support/vitalis.svg"
import obeconLogo from "@/images/support/obecon.svg"
import celeritasLogo from "@/images/support/celeritas.svg"
import olimPocketLogo from "@/images/support/olimpiada_pocket.svg"
import oblinLogo from "@/images/support/oblin.svg"
import primaryCornerTl from "@/images/corners/primary-corner-tl.svg"
import primaryCornerTr from "@/images/corners/primary-corner-tr.svg"
import primaryCornerBr from "@/images/corners/primary-corner-br.svg"
import secondaryCornerTl from "@/images/corners/secondary-corner-tl.svg"
import secondaryCornerTr from "@/images/corners/secondary-corner-tr.svg"
import secondaryCornerBr from "@/images/corners/secondary-corner-br.svg"
import secondaryCornerBl from "@/images/corners/secondary-corner-bl.svg"

import partnerLogo1 from "@/images/partners/fractal.svg"
import partnerLogo2 from "@/images/partners/ministerio.svg"

import molecula from "@/images/about/molecula.png"
import grid from "@/images/about/grid.png"
import circleShape from "@/images/about/about-shape.svg"
import aboutUsImg from "@/images/about/aboutUs.png"

import phone from "@/images/app/app-phone.png"
import appStore from "@/images/app/appstore.png"
import playStore from "@/images/app/googleplay.png"

import videoShape from "@/images/video/video-shape.svg"
import videoArrow from "@/images/video/arrow.svg"

import step1Image from "@/images/steps/step1.png"
import step2Image from "@/images/steps/step2.png"
import step3Image from "@/images/steps/step3.png"
import step4Image from "@/images/steps/step4.png"

import handIcon from "@/images/icon-hand.svg"

export const LogoImage = {
  light: logoLight,
  dark: logoDark,
  fractal: logoFractal,
};

export const FooterImage = {
  appStore: appStoreFooter,
  playStore: playStoreFooter
};

export const HandIcon = {
  iconHand: handIcon,
};

export const SupportImage = {
  vertere: vertereLogo,
  fractalVertical: fractalLogo,
  ministeiro: minLogo,
  sapientia: sapientiaLogo,
  vitalis: vitalisLogo,
  obecon: obeconLogo,
  celeritas: celeritasLogo,
  olimPocket: olimPocketLogo,
  oblin: oblinLogo,
};

export const PartnersImage = {
   items: [
    {
      image: partnerLogo1,
    },
    {
      image: partnerLogo2,
    },
  ],
}

export const Corners = {
  primaryCTL: primaryCornerTl,
  primaryCTR: primaryCornerTr,
  primaryCBR: primaryCornerBr,
  secondaryCBR: secondaryCornerBr,
  secondaryCTR: secondaryCornerTr,
  secondaryCTL: secondaryCornerTl,
  secondaryCBL: secondaryCornerBl,
}

export const stepsImage = {
  step1: step1Image,
};

export const NavLinks = [
  {
    name: "Quem pode participar?",
    url: "#quem-pode",
  },
  {
    name: "Como participar?",
    url: "#como-participar",
  },
  {
    name: "Edições",
    url: "#edicoes",
  },
  {
    name: "Cronograma",
    url: "#cronograma",
  },
  {
    name: "Sobre nós",
    url: "/sobre-nos",
  },
];

export const NavInnerLinks = [
  {
    name: "Quem pode participar?",
    url: "/#quem-pode",
  },
  {
    name: "Como participar?",
    url: "/#como-participar",
  },
  {
    name: "Edições",
    url: "/#edicoes",
  },
  {
    name: "Cronograma",
    url: "/#cronograma",
  },
  {
    name: "Sobre nós",
    url: "/sobre-nos",
  },
];


export const SliderOneData = [
  {
    image: sliderOne01,
    shape: shape01,
    subTitle: "Aprofunde o seu conhecimento das matérias mais cobradas pelo governo federal e as principais instituições. É para você e é gratuita.",
    title: "A única olimpíada 100% \n focada ",
    highlight: "em medicina",
    button: {
      label: "Fazer minha inscrição",
      url: "https://app.olimpiadademedicina.org/",
    },
    
  },
  // {
  //   image: sliderOne01,
  //   shape: shape01,
  //   subTitle: "Atual, integrada, completa e moderna",
  //   title: "A única olimpíada 100% \n focada",
  //   highlight: "em medicina",
  //   button: {
  //     label: "Fazer minha inscrição",
  //     url: "/about",
  //   },
  // }
];

export const SliderAboutData = [
  {
    image: slideAbout,
    shape: shape02,
    subTitle: "A Vitalis é composta por profissionais das mais diversas áreas com a máxima excelência. Somos uma equipe diversificada e com o mesmo objetivo.",
    title: "Nosso ",
    highlight: "time",
    button: {
      label: "Fazer minha inscrição",
      url: "/about",
    },
    
  }
];

export const Indicators = [
  {
    title: "Cidades participantes",
    indNumber: "640",
  },
  {
    title: "Inscritos",
    indNumber: "+20 mil",
  },
  {
    title: "são participantes mulheres",
    indNumber: "69%",
  },
  {
    title: "Escolas públicas",
    indNumber: "49%",
  },
];

export const FaqData = [
  {
    question: "A Vitalis tem medalha?",
    response: "Os participantes são premiados com certificados, a depender das categorias e classificações.",
  },
  {
    question: "Como estudar para a Vitalis?",
    response: "Acesse o Syllabus (plano de estudos) e siga as referências acadêmicas que estão aqui no site.",
  },
  {
    question: "Só pode participar da Vitalis quem vai fazer Medicina?",
    response: "Não, a olimpíada é aberta para todos os interessados pelas áreas de saúde, biologia e medicina.",
  },
  {
    question: "Estou no 4º ano do ensino técnico integrado ao ensino médio, coloco pré-vestibular ou terceira série na inscrição?",
    response: "Terceira série",
  },
  {
    question: "Concluí o ensino médio há dois anos, é viável a participação na modalidade Sênior Regular?",
    response: "Não, essa modalidade é para quem está no ensino médio ou concluiu no ano anterior. Pode escolher a Livre.",
  },
];

export const Periodos = [
  {
    dataInicial: "12",
    mesInicial: "agosto",
    dataFinal: "17",
    mesFinal: "agosto",
    title: "Prova Online",
    subTitle: "Prova aberta entre 12/08 (16h) e 17/08 (23h59min)",
  },
  {
    dataInicial: "28",
    mesInicial: "agosto",
    title: "Gabarito provisório",
    subTitle: "Divulgação do gabarito provisório: 28/08 (a partir das 16h)",
  },
  {
    dataInicial: "29",
    mesInicial: "agosto",
    title: "Recurso de correção",
    subTitle: "Prazo para recurso de correção: até 29/08 (até 16h)",
  },
  {
    dataInicial: "30",
    mesInicial: "agosto",
    title: "Gabarito Definitivo",
    subTitle: "Divulgação do gabarito definitivo: 30/08 (a partir das 16h)",
  },
  {
    dataInicial: "02",
    mesInicial: "setembro",
    title: "Classificação",
    subTitle: "Divulgação dos classificados para a Terceira Fase: 02/09 (a partir das 16h)",
  },
];

export const Periodos2 = [
  {
    dataInicial: "28",
    mesInicial: "setembro",
    dataFinal: "29",
    mesFinal: "setembro",
    title: "Prova Presencial",
    subTitle: "Prova nos dias 28 e 29/09 (09h até 16h) - Local a definir",
  },
  {
    dataInicial: "30",
    mesInicial: "setembro",
    title: "Gabarito provisório",
    subTitle: "Divulgação do gabarito provisório: 30/09 (a partir das 14h)",
  },
  {
    dataInicial: "20",
    mesInicial: "outubro",
    title: "Medalhas/Certificados",
    subTitle: "Emissão de medalhas/certificados para Categoria Júnior e Sênior: 20/10 (a partir das 18h)",
  },
];


export const SliderTwoData = [
  {
    image: video02,
    name: "Cecília de Alcântara",
    title: "Testemunho da",
    arrow: videoArrow,
  },
  // {
  //   image: sliderTwo02,
  //   name: "Jackson Luciano",
  //   title: "Testemunho do",
  //   arrow: videoArrow,
  // },
];


export const SliderThreeData = [
  {
    image: sliderThree01,
    subTitle: "Lorem ipsum dolor sit amet",
    title: "SMART WEB\n DESIGN AGENCY.",
    button: {
      label: "LOREM IPSUM",
      url: "/about",
    },
  },
  {
    image: sliderThree02,
    subTitle: "Lorem ipsum dolor sit amet",
    title: "SMART WEB\n DESIGN AGENCY.",
    button: {
      label: "LOREM IPSUM",
      url: "/about",
    },
  },
];

export const ContactInfosBlock = {
  subTitle: "LOREM IPSUM",
  title: "Lorem ipsum dolor",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet \n libero in augue volutpat varius.",
};

export const ContactInfosList = [
  {
    title: "lorem",
    infos: [
      {
        name: "Lorem ipsum dolor sit",
      },
      {
        name: "lorem@ipsum.com",
      },
      {
        name: "666 888 000",
      },
    ],
  },
  {
    title: "lorem",
    infos: [
      {
        name: "Lorem ipsum dolor sit",
      },
      {
        name: "lorem@ipsum.com",
      },
      {
        name: "666 888 000",
      },
    ],
  },
  {
    title: "lorem",
    infos: [
      {
        name: "Lorem ipsum dolor sit",
      },
      {
        name: "lorem@ipsum.com",
      },
      {
        name: "666 888 000",
      },
    ],
  },
  {
    title: "lorem",
    infos: [
      {
        name: "Lorem ipsum dolor sit",
      },
      {
        name: "lorem@ipsum.com",
      },
      {
        name: "666 888 000",
      },
    ],
  },
];

export const ContactFormTitle = {
  subTitle: "Lorem ipsum dolor",
  title: "Lorem ipsum dolor sit",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet \n libero in augue volutpat varius.",
};


export const BlogData = [
  {
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage1,
  },
  {
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage2,
  },
  {
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage3,
  },
  {
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage4,
  },
  {
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage5,
  },
  {
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage6,
  },
];


export const BlogTwoData = [
  {
    title:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit.",
    date: "20 nov",
    url: "/blog-single",
    image: blogImageTwo1,
  },
  {
    title: "Lorem ipsum dolor sit amet, consectetur",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage2,
  },
  {
    title: "Lorem ipsum dolor sit amet, consectetur",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage3,
  },
  {
    title: "Lorem ipsum dolor sit amet, consectetur",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage4,
  },
  {
    title: "Lorem ipsum dolor sit amet, consectetur",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage5,
  },
  {
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit.",
    date: "20 nov",
    url: "/blog-single",
    image: blogImageTwo2,
  },
  {
    title: "Lorem ipsum dolor sit amet,",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage6,
  },
];

export const BlogHomeSection = {
  subTitle: "Lorem ipsum",
  title: "Lorem ipsum dolor",
  text:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet \n libero in augue volutpat varius.",
};


export const BlogSidebarPost = [
  {
    title: "basic rules of running web agency",
    image: blogS1,
    url: "/blog-single",
  },
  {
    title: "basic rules of running web agency",
    image: blogS2,
    url: "/blog-single",
  },
  {
    title: "basic rules of running web agency",
    image: blogS3,
    url: "/blog-single",
  },
];


export const BlogComments = [
  {
    image: commentImage1,
    name: "Lorem Ipsum",
    data: "20 Nov, 2018 - 4:00 pm",
    content:
      "Lorem Ipsum is simply dummy text of the rinting and typesetting been the industry standard dummy text ever sincer condimentum purus. In non ex at ligula fringilla lobortis et not the aliquet.",
  },
  {
    image: commentImage2,
    name: "Lorem Ipsum",
    data: "20 Nov, 2018 - 4:00 pm",
    content:
      "Lorem Ipsum is simply dummy text of the rinting and typesetting been the industry standard dummy text ever sincer condimentum purus. In non ex at ligula fringilla lobortis et not the aliquet.",
  },
];


export const PortfolioData = [
  {
    title: "LOREM IPSUM",
    categories: ["all", "graphic"],
    image: portfolio01,
    url: "/portfolio-details",
  },
  {
    title: "LOREM IPSUM",
    categories: ["all", "graphic"],
    image: portfolio02,
    url: "/portfolio-details",
  },
  {
    title: "LOREM IPSUM",
    categories: ["all", "graphic"],
    image: portfolio03,
    url: "/portfolio-details",
  },
  {
    title: "LOREM IPSUM",
    categories: ["all", "graphic"],
    image: portfolio04,
    url: "/portfolio-details",
  },
  {
    title: "LOREM IPSUM",
    categories: ["all", "graphic"],
    image: portfolio05,
    url: "/portfolio-details",
  },
  {
    title: "LOREM IPSUM",
    categories: ["all", "graphic"],
    image: portfolio06,
    url: "/portfolio-details",
  },
];

export const PortfolioFilters = [
  { name: "all" },
  { name: "graphic" },
  { name: "branding" },
  { name: "marketing" },
  { name: "logos" },
];

export const PortfolioDetailsData = {
  gallery: [{ image: portfolioD01 }, { image: portfolioD02 }],
  title: "LOREM IPSUM",
  text:
    " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet libero in augue volutpat varius. Proin at elit ac nibh facilisis fermentum sit amet at mauris.",
  client: "Lorem ipsum",
  categories: [
    {
      name: "Lorem,",
      url: "#",
    },
    {
      name: "Lorem,",
      url: "#",
    },
  ],
  date: "8 Nov, 2018",
  socials: [
    {
      name: "Facebook",
      url: "#",
    },
    {
      name: "Twitter",
      url: "#",
    },
    {
      name: "Linkedin",
      url: "#",
    },
  ],
};

export const ServicePostData = [
  {
    title: "Lorem Ipsum",
    text:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    iconName: "mei-settings",
    url: "/service-details",
  },
  {
    title: "Lorem Ipsum",
    text:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    iconName: "mei-transfer",
    url: "/service-details",
  },
  {
    title: "Lorem Ipsum",
    text:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    iconName: "mei-pie-chart",
    url: "/service-details",
  },
];

export const ServiceHomeTwoData = {
  subTitle: "Lorem ipsum dolor sit amet",
  title: "Lorem ipsum dolor sit amet, \n consectetur adipiscing elit.",
  text:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet \n libero in augue volutpat varius.",
};

export const ServiceHomeThreeData = {
  subTitle: "Lorem ipsum dolor sit",
  title: "Lorem ipsum",
  text:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet \n libero in augue volutpat varius.",
};

export const ServicePostTwoData = {
  sectionContent: {
    title: "Lorem ipsum dolor sit amet, \n consectetur adipiscing elit.",
    subTitle: "Lorem ipsum dolor sit",
    text:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet \n libero in augue volutpat varius.",
  },
  posts: [
    {
      title: "Lorem ipsum",
      iconName: "mei-web-design",
      url: "/service-details",
    },
    {
      title: "Lorem ipsum",
      iconName: "mei-computer-graphic",
      url: "/service-details",
    },
    {
      title: "Lorem ipsum",
      iconName: "mei-development-1",
      url: "/service-details",
    },
    {
      title: "Lorem ipsum",
      iconName: "mei-development",
      url: "/service-details",
    },
    {
      title: "Lorem ipsum",
      iconName: "mei-app-development",
      url: "/service-details",
    },
  ],
};

export const ServicePostThreeData = {
  sectionContent: {
    title: "Lorem ipsum dolor",
    subTitle: "Lorem ipsum dolor",
    text:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet \n libero in augue volutpat varius.",
  },
  posts: [
    {
      title: "Lorem ipsum",
      image: serviceOne01,
      url: "/service-details",
    },
    {
      title: "Lorem ipsum",
      image: serviceOne02,
      url: "/service-details",
    },
    {
      title: "Lorem ipsum",
      image: serviceOne03,
      url: "/service-details",
    },
  ],
};

export const comoParticiparData = {
  sectionContent: {
    title: "Como Participar?",
    subTitle: "Siga os passos abaixo para participar da olimpíada",
    text:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet \n libero in augue volutpat varius.",
  },
  posts: [
    {
      id: 1,
      title: "Faça a sua inscrição",
      text: "Crie seu login e preencha seus dados para fazer a sua inscrição, aqui no site ou no app Vitalis. É importante baixar o app para ter uma experiência completa na olimpíada!",
      image: step1Image,
      url: "/service-details",
    },
    {
      id: 2,
      title: "Receba o e-mail de confirmação",
      text: "Após o e-mail de 'inscrição confirmada', é só aguardar a liberação das fases. Aproveite para reler o regulamento e estudar!",
      image: step2Image,
      url: "/service-details",
    },
    {
      id: 3,
      title: "Prepare-se",
      text: "Acesse a prova da edição anterior e pesquise as referências acadêmicas disponíveis aqui.", 
      image: step3Image,
      url: "/service-details",
    },
    {
      id: 4,
      title: "Faça a prova",
      text: "Acompanhe as datas das fases para não perder nenhum prazo! As provas ficam disponíveis na sua área de usuário (no app e no site).", 
      image: step4Image,
      url: "/service-details",
    },
  ],
};

export const FunfactData = [
  {
    title: "Lorem ipsum",
    countNumber: 705,
  },
  {
    title: "Lorem ipsum",
    countNumber: 480,
  },
  {
    title: "Lorem ipsum",
    countNumber: 626,
  },
  {
    title: "Lorem ipsum",
    countNumber: 774,
  },
];

export const TrustClientData = {
  image: trustClient01,
  title: "Lorem ipsum dolor sit amet, consectetur adipiscing",
  text:
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidi-dunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercita-tion ullamco laboris nisi ut aliquip ex ea commodo.",
  url: "/about",
};

export const ClientCarouselData = {
  sectionContent: {
    title: "Lorem ipsum dolor",
    subTitle: "Lorem ipsum",
    text:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet \n libero in augue volutpat varius.",
  },
  items: [
    {
      title: "Júnior",
      type: "Regular",
      desc: "Alunos do 6º ao 9º ano do Ensino Fundamental II.",
      url: "#",
      showProfile:"jrHandleShow",
    },
    {
      title: "Júnior",
      type: "Livre",
      desc: "Qualquer pessoa interessada em Medicina e que queira fazer as provas da categoria Júnior Regular.",
      url: "#",
      showProfile:"jlHandleShow",
    },
    {
      title: "Sênior",
      type: "Regular",
      desc: "Alunos do Ensino Médio, incluindo o 4º ano de cursos",
      url: "#",
      showProfile:"srHandleShow",
    },
    {
      title: "Sênior",
      type: "Livre",
      desc: "Qualquer pessoa interessada em Medicina e que queira fazer as provas da categoria Sênior Regular.",
      url: "#",
      showProfile:"slHandleShow",
    },
  ],
};

export const AboutOneData = {
  sectionContent: {
    title: "Lorem ipsum dolor sit amet, consectetur adipisicing elit",
    subTitle: "Lorem ipsum dolor",
  },
  gallery: [aboutOne01, aboutOne02],
  counter: {
    title: "Lorem ipsum",
    number: 1990,
  },
};

export const TeamOneData = {
  sectionContent: {
    title: "Lorem ipsum dolor",
    subTitle: "Lorem ipsum dolor dolor",
    text:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet \n libero in augue volutpat varius.",
  },
  posts: [
    {
      image: team01,
      name: "Lorem ipsum",
      designation: "Lorem ipsum",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team02,
      name: "Lorem ipsum",
      designation: "Lorem ipsum",
      url: "",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team03,
      name: "Lorem ipsum",
      designation: "Lorem ipsum",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team04,
      name: "Lorem ipsum",
      designation: "Lorem ipsum",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team05,
      name: "Lorem ipsum",
      designation: "Lorem ipsum",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
  ],
};

export const VideoOneData = {
  sectionContent: {
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    subTitle: "Lorem ipsum dolor",
    text:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet libero in augue volutpat varius. Proin at elit ac nibh facilisis fermentum sit amet at mauris.",
  },
  video: {
    image: video01,
    ID: "y2Eqx6ys1hQ",
    title: "Watch Video",
  },
};

export const SubscribeFormData = {
  sectionContent: {
    title: "Lorem ipsum ",
    subTitle: "Lorem ipsum dolor sit amet",
  },
};

export const TestimonialsOneData = {
  sectionContent: {
    subTitle: "Lorem ipsum",
    title: "Lorem ipsum",
  },
  posts: [
    {
      name: "Lorem ipsum",
      designation: "Lorem",
      image: testimonial01,
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet libero in augue volutpat varius. Proin at elit ac nibh facilisis fermentum sit amet at mauris. Nunc sed ipsum malesuada, lacinia risus vitae, blandit ex.",
      date: "25 nov, 2018",
    },
    {
      name: "Lorem ipsum",
      designation: "Lorem",
      image: testimonial02,
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet libero in augue volutpat varius. Proin at elit ac nibh facilisis fermentum sit amet at mauris. Nunc sed ipsum malesuada, lacinia risus vitae, blandit ex.",
      date: "25 nov, 2018",
    },
    {
      name: "Lorem ipsum",
      designation: "Lorem",
      image: testimonial03,
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet libero in augue volutpat varius. Proin at elit ac nibh facilisis fermentum sit amet at mauris. Nunc sed ipsum malesuada, lacinia risus vitae, blandit ex.",
      date: "25 nov, 2018",
    },
    {
      name: "Lorem ipsum",
      designation: "Lorem",
      image: testimonial01,
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet libero in augue volutpat varius. Proin at elit ac nibh facilisis fermentum sit amet at mauris. Nunc sed ipsum malesuada, lacinia risus vitae, blandit ex.",
      date: "25 nov, 2018",
    },
    {
      name: "Lorem ipsum",
      designation: "Lorem",
      image: testimonial02,
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet libero in augue volutpat varius. Proin at elit ac nibh facilisis fermentum sit amet at mauris. Nunc sed ipsum malesuada, lacinia risus vitae, blandit ex.",
      date: "25 nov, 2018",
    },
    {
      name: "Lorem ipsum",
      designation: "Lorem",
      image: testimonial03,
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet libero in augue volutpat varius. Proin at elit ac nibh facilisis fermentum sit amet at mauris. Nunc sed ipsum malesuada, lacinia risus vitae, blandit ex.",
      date: "25 nov, 2018",
    },
    {
      name: "Lorem ipsum",
      designation: "Lorem",
      image: testimonial01,
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet libero in augue volutpat varius. Proin at elit ac nibh facilisis fermentum sit amet at mauris. Nunc sed ipsum malesuada, lacinia risus vitae, blandit ex.",
      date: "25 nov, 2018",
    },
    {
      name: "Lorem ipsum",
      designation: "Lorem",
      image: testimonial02,
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet libero in augue volutpat varius. Proin at elit ac nibh facilisis fermentum sit amet at mauris. Nunc sed ipsum malesuada, lacinia risus vitae, blandit ex.",
      date: "25 nov, 2018",
    },
    {
      name: "Lorem ipsum",
      designation: "Lorem",
      image: testimonial03,
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet libero in augue volutpat varius. Proin at elit ac nibh facilisis fermentum sit amet at mauris. Nunc sed ipsum malesuada, lacinia risus vitae, blandit ex.",
      date: "25 nov, 2018",
    },
  ],
};

export const FeatureTabData = {
  sectionContent: {
    title: "Lorem ipsum",
    subTitle: "Lorem ipsum dolor",
    text:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet \n libero in augue volutpat varius.",
  },
  posts: [
    {
      title: "Lorem ipsum",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet libero in augue volutpat varius. Proin at elit ac nibh facilisis fermentum sit amet at mauris. Nunc sed ipsum malesuada, lacinia risus vitae, blandit ex. Curabitur aliquet nec tortor eget blandit. Sed consectetur pellentesque aliquam. Fusce id malesuada sapien. Suspendisse vel vulputate libero. ",
      image: featureTab01,
      lists: [
        {
          item: "Lorem ipsum dolor sit amet, consectetur",
        },
        {
          item: "Lorem ipsum dolor sit amet, consectetur",
        },
        {
          item: "Lorem ipsum dolor sit amet, consectetur",
        },
        {
          item: "Lorem ipsum dolor sit amet, consectetur",
        },
        {
          item: "Lorem ipsum dolor sit amet, consectetur",
        },
        {
          item: "Lorem ipsum dolor sit amet, consectetur",
        },
      ],
    },
    {
      title: "Lorem ipsum",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet libero in augue volutpat varius. Proin at elit ac nibh facilisis fermentum sit amet at mauris. Nunc sed ipsum malesuada, lacinia risus vitae, blandit ex. Curabitur aliquet nec tortor eget blandit. Sed consectetur pellentesque aliquam. Fusce id malesuada sapien. Suspendisse vel vulputate libero. ",
      image: featureTab02,
      lists: [
        {
          item: "SLorem ipsum dolor sit amet, consectetur",
        },
        {
          item: "Lorem ipsum dolor sit amet, consectetur",
        },
        {
          item: "Lorem ipsum dolor sit amet, consectetur",
        },
        {
          item: "Lorem ipsum dolor sit amet, consectetur",
        },
        {
          item: "Lorem ipsum dolor sit amet, consectetur",
        },
        {
          item: "Lorem ipsum dolor sit amet, consectetur",
        },
      ],
    },
    {
      title: "Lorem ipsum dolor",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet libero in augue volutpat varius. Proin at elit ac nibh facilisis fermentum sit amet at mauris. Nunc sed ipsum malesuada, lacinia risus vitae, blandit ex. Curabitur aliquet nec tortor eget blandit. Sed consectetur pellentesque aliquam. Fusce id malesuada sapien. Suspendisse vel vulputate libero. ",
      image: featureTab03,
      lists: [
        {
          item: "SLorem ipsum dolor sit amet, consectetur",
        },
        {
          item: "Lorem ipsum dolor sit amet, consectetur",
        },
        {
          item: "Lorem ipsum dolor sit amet, consectetur",
        },
        {
          item: "Lorem ipsum dolor sit amet, consectetur",
        },
        {
          item: "Lorem ipsum dolor sit amet, consectetur",
        },
        {
          item: "Lorem ipsum dolor sit amet, consectetur",
        },
      ],
    },
  ],
};

export const ParallaxOneData = {
  iconName: "mei-team",
  title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  specialText: "Lorem ipsum dolor sit amet.",
  text:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet \n libero in augue volutpat varius.",
};

export const PortfolioHomeData = {
  sectionContent: {
    title: "Lorem ipsum",
    subTitle: "Lorem ipsum",
    text:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet \n libero in augue volutpat varius.",
  },
};

export const VideoTwoData = {
  sectionContent: {
    title: "Lorem ipsum",
    subTitle: "Lorem ipsum dolor",
    text:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet \n libero in augue volutpat varius.",
  },
  video: {
    title: "Watch Video",
    ID: "y2Eqx6ys1hQ",
    image: video02,
    shape: videoShape,
  },
};


export const AboutTwoData = {
  sectionContent: {
    title: "Atual, integrada,",
    highlight: "completa e moderna",
    text:
      "As carreiras médicas exercem um enorme fascínio sobre os jovens. Por outro lado, o nível de conhecimento sobre os caminhos de formação e atuação nas áreas da saúde ainda são pouco exploradas na educação básica, onde o debate sobre tendências da medicina e competências necessárias para o sucesso na carreira (ex.: fluência em tecnologia, economia e gestão) ainda são escassos..",
    img1: molecula,
    img2: grid,
    img3: circleShape,
  },
  button: {
    label: "Saiba mais...",
    url: "/sobre-nos",
  },
  gallery: [aboutTwo01, aboutTwo02],
};

export const AboutUsData = {
  sectionContent: {
    title: "Diversos, amplos",
    highlight: "especializados e excelentes,",
    text:
      "A Vitalis é organizada por um grupo de professores de biologia, de médicos, de acadêmicos de medicina e de psicologia que participam de forma inteiramente voluntária e baseada no brilho que a olimpíada tem para eles. \n\n Os nomes atuais incluem, entre outros mais profissionais de diversas outras áreas:",
    img1: aboutUsImg,
  },
  button: {
    label: "Saiba mais...",
    url: "#",
  },
  gallery: [aboutTwo01, aboutTwo02],
};

export const appData = {
  sectionContent: {
    title: 'Baixe o App da Vitalis \n e tenha a Olimpíada na',
    highlight: "sua mão",
    text: "Para participar da Olimpíada Brasilera de Medicina será necessário o uso do nosso APP.",
    img1: phone,
    img2: appStore,
    img3: playStore,
  },
  button: {
    label: "Saiba mais...",
    google: "https://play.google.com/store/apps/details?id=br.com.fractaltecnologia.vitalis",
    apple: "https://apps.apple.com/br/app/vitalis/id1492487445"
  },
};

export const FeatureTwoData = {
  sectionContent: {
    title: "Lorem ipsum",
    subTitle: "Lorem ipsum dolor",
    text:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet \n libero in augue volutpat varius. ",
  },
  posts: [
    {
      title: "Lorem ipsum dolor sit",
      text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet libero",
    },
    {
      title: "Lorem ipsum dolor sit",
      text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet libero",
    },
    {
      title: "Lorem ipsum dolor sit",
      text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet libero",
    },
  ],
  image: {
    text: "Lorem ipsum dolor sit amet \n consectetur adipiscing.",
    path: featureTwo01,
  },
};

export const CallToActionTwoData = [
  {
    label: "View our Recent Work",
    url: "/portfolio",
  },
  {
    label: "Reqeust a free quote",
    url: "/contact",
  },
];
