/** @jsx jsx */
import React, { useContext, Fragment, useRef } from "react";
import { Link } from "gatsby";
import { MenuContext } from "@/context/menu-context";
import { NavLinks, NavInnerLinks } from "@/data";
import { jsx } from "@emotion/react";

import {
  showOverlayNav,
  popUpMenu,
  closepopUp,
  popUpSocial,
  popUpInner,
  dlMenuWrap,
  subMenu,
  popUp,
} from "../assets/styles/PopUpMenu.styles";

import {
  commonBtn2,
} from "../assets/styles/layout.styles";

import { commonBtn } from "../assets/styles/layout.styles";

const PopupMenu = () => {
  const { menuStatus, updateMenuStatus } = useContext(MenuContext);
  const menuEl = useRef(null);
  const handleMenuClick = (e) => {
    e.preventDefault();
    updateMenuStatus(!menuStatus);
  };

  const handleMenuMobClick = () => {
    updateMenuStatus(!menuStatus);
  };

  return (
    <div className="show-overlay-nav" css={showOverlayNav}>
      <div className="popup popup__menu" css={[popUp, popUpMenu]}>
        <a
          href=""
          id="close-popup"
          onClick={handleMenuClick}
          css={closepopUp}
        ></a>
        <div className="container mobileContainer">
          <div className="row"></div>
          <div className="row">
            <div className="col-lg-12">
              <div css={popUpInner} className="popup-inner">
                <nav
                  css={dlMenuWrap}
                  className="popup-menu dl-menu__wrap"
                  ref={menuEl}
                >
                  <ul className="popup-desktop d-none d-lg-block">
                    {NavLinks.map((links, index) => {
                      return (
                        <li
                          key={index}
                          className={`${
                            undefined !== links.subItems
                              ? "menu-item-has-children"
                              : ""
                          }`}
                        >
                          <Link to={links.url} onClick={handleMenuMobClick}>
                            {links.name}
                          </Link>
                          {undefined !== links.subItems ? (
                            <Fragment>
                              <button
                                onClick={(e) => {
                                  menuEl.current
                                    .querySelectorAll(".sub-menu")
                                    .forEach((item) => {
                                      item.classList.remove("show");
                                    });

                                  let clickedItem = e.currentTarget.parentNode;
                                  clickedItem
                                    .querySelector(".sub-menu")
                                    .classList.toggle("show");
                                }}
                              >
                                <i className="fa fa-angle-down"></i>
                              </button>
                              <ul css={subMenu} className="sub-menu">
                                {links.subItems.map((subLinks, index) => (
                                  <li key={index}>
                                    <Link to={subLinks.url}>
                                      {subLinks.name}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </Fragment>
                          ) : null}
                        </li>
                      );
                    })}
                  </ul>
                  <ul className="popup-mobile d-block d-md-block d-lg-none">
                    {NavInnerLinks.map((links, index) => {
                      return (
                        <li
                          key={index}
                          className={`${
                            undefined !== links.subItems
                              ? "menu-item-has-children"
                              : ""
                          }`}
                        >
                          <Link to={links.url} onClick={handleMenuMobClick}>
                            {links.name}
                          </Link>
                          {undefined !== links.subItems ? (
                            <Fragment>
                              <button
                                onClick={(e) => {
                                  menuEl.current
                                    .querySelectorAll(".sub-menu")
                                    .forEach((item) => {
                                      item.classList.remove("show");
                                    });

                                  let clickedItem = e.currentTarget.parentNode;
                                  clickedItem
                                    .querySelector(".sub-menu")
                                    .classList.toggle("show");
                                }}
                              >
                                <i className="fa fa-angle-down"></i>
                              </button>
                              <ul css={subMenu} className="sub-menu">
                                {links.subItems.map((subLinks, index) => (
                                  <li key={index}>
                                    <Link to={subLinks.url}>
                                      {subLinks.name}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </Fragment>
                          ) : null}
                        </li>
                      );
                    })}
                  </ul>
                </nav>
                <Link to="https://app.olimpiadademedicina.org/" className="mobile__bt" css={commonBtn}>
                  Fazer minha inscrição
                </Link>
                <a href="/prova" target="_blank" className="mobile__bt" css={commonBtn2}>
                  Provas Anteriores
                </a>
                <Link to="https://fractalid.fractaltecnologia.com.br/login?app_id=26&url_redirect=https%3A%2F%2Fapp.olimpiadademedicina.org%2F" className="mobile__bt" css={commonBtn}>
                  Login
                </Link>

                <div css={popUpSocial} className="text-center text-md-right">
                  <ul>
                    <li>
                      <a target="_blank" href="https://www.facebook.com/olimpiadademedicina.br/">
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.instagram.com/olimpiadademedicina.br/">
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupMenu;
