/** @jsx jsx */
import { css } from "@emotion/react";

export const colors = {
  gray: "#efefef",
  white: "#ffffff",
  black: "#1e1e1e",
  primary: "#545cff",
  primaryVar1: "#343bbf",
  primaryVar2: "#272d9f",
  primaryVar3: "#434bdf",
  secondary: "#56f0be",
  secondaryVar1: "#45d8a8",
  secondaryVar2: "#36be92",
  secondaryVar3: "#64f896"
};

export const commonSection = css`
  padding: 120px 0;
  position: relative;

  .container {
    position: relative;
    z-index: 2;
  }
`;

export const subtitle = css`
  font-size: 24px;
  line-height: 28.8px;
  font-weight: 400;
  color: #818181;
  letter-spacing: 0;
  font-family: 'Titillium Web', sans-serif;
`;

export const secTitle = css`
  font-size: 56px;
  line-height: 67px;
  font-weight: 700;
  color: #000;
  letter-spacing: 0;
  margin: 0 0 25px;
  white-space: pre-line;
  font-family: 'Titillium Web', sans-serif;

  /* @media (min-width: 320px) and (max-width: 767px) {
    font-size: 34px;
    letter-spacing: 1px;
    line-height: 50px;
  }
  @media (min-width: 768px) and (max-width: 990px) {
    font-size: 40px;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    font-size: 44px;
  } */
`;

export const secDesk = css`
  margin: 0 0 54px;
  white-space: pre-line;
`;

export const white = css`
  color: #ffffff;
`;

export const redColor = css`
  color: #e62b4a;
`;

export const colorAaa = css`
  color: #aaaaaa;
`;

export const commonBtn = css`
  top:0;
  right:0;
  padding: 2px 16px;
  box-shadow: 5px 5px 0px 0px #383838;
  border: 1px solid #383838;
  border-radius: 10px;
  font-family: 'Titillium Web', sans-serif;
  font-size: 16px;
  font-weight: 600;
  background: ${colors.secondaryVar1};
  color: ${colors.white};
  text-align: center;
  display: inline-block;
  position: relative;
  outline: none;
  cursor: pointer;
  transition: all 0.19s linear 0s;
  -moz-transition: all 0.19s linear 0s;
  -webkit-transition: all 0.19s linear 0s;
  -ms-transition: all 0.19s linear 0s;
  -o-transition: all 0.19s linear 0s;

  &:hover {
    background: ${colors.secondaryVar3};
    color: #000;
    text-decoration: none;
    top:5px;
    right:-5px;
    box-shadow: 2px 2px 0px #383838;
    &:after {
      width: 100%;
    }
  }

  /* &:after {
    content: "";
    width: 4px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background: #fff;
    transition: all 0.4s ease-out;
    -moz-transition: all 0.4s ease-out;
    -webkit-transition: all 0.4s ease-out;
    -ms-transition: all 0.4s ease-out;
    -o-transition: all 0.4s ease-out;
  } */

  span {
    position: relative;
    z-index: 2;
  }
`;

export const commonBtn2 = css`
  top:0;
  right:0;
  padding: 2px 16px;
  box-shadow: 5px 5px 0px 0px #383838;
  border: 1px solid #383838;
  border-radius: 10px;
  font-family: 'Titillium Web', sans-serif;
  font-size: 16px;
  font-weight: 600;
  background: #434BDF;
  color: ${colors.white};
  text-align: center;
  display: inline-block;
  margin-right: 6px;
  position: relative;
  outline: none;
  cursor: pointer;
  transition: all 0.19s linear 0s;
  -moz-transition: all 0.19s linear 0s;
  -webkit-transition: all 0.19s linear 0s;
  -ms-transition: all 0.19s linear 0s;
  -o-transition: all 0.19s linear 0s;
  &:hover {
    background: #3841FF;
    color: #000;
    text-decoration: none;
    top:5px;
    right:-5px;
    box-shadow: 2px 2px 0px #383838;
    &:after {
      width: 100%;
    }
  }
  /* &:after {
    content: "";
    width: 4px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background: #fff;
    transition: all 0.4s ease-out;
    -moz-transition: all 0.4s ease-out;
    -webkit-transition: all 0.4s ease-out;
    -ms-transition: all 0.4s ease-out;
    -o-transition: all 0.4s ease-out;
  } */
  span {
    position: relative;
    z-index: 2;
  }
`;

export const redBg = css`
  padding: 28px 40.5px;
  &:hover {
    color: #fff;
    &:after {
      width: 100%;
    }
  }
  &:after {
    background: #e62b4a;
  }
`;

export const showIt = css`
  background: #e62b4a;
  bottom: 60px;
  color: #ffffff;
  font-size: 20px;
  height: 40px;
  line-height: 0.8;
  padding: 10px 12px;
  position: fixed;
  right: 60px;
  text-align: center;
  width: 40px;
  z-index: 99;
  cursor: pointer;

  &:hover {
    background: #000;
    color: #fff;
  }
`;

export const pb120 = css`
  padding-bottom: 120px;
`;

export const mb30 = css`
  margin-bottom: 30px;
`;

export const inputForm = css`
  width: 100%;
  height: 70px;
  background: #f4f4f4;
  border: none;
  text-align: left;
  padding: 0 30px;
  font-size: 14px;
  letter-spacing: 0.56px;
  line-height: 70px;
  color: #818181;
  margin: 0 0 30px;
  outline: none;
  border-radius: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;

  &:focus{
    -webkit-box-shadow: 0px 10px 35px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 10px 35px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 35px 0px rgba(0, 0, 0, 0.05);
  border-right: 4px solid #e62b4a;
  background: #fff;
  }

  &.reqError {
    border: 1px solid #e62b4a;
  }
`;

export const textarea = css`
   height: 190px;
  resize: none;
  line-height: 30px;
  padding: 21px 30px;
  margin-bottom: 21px;
`

export const noPadding = css`
  padding:0;
`

export const noPaddingRight = css`
  padding-right:0;
`

export const noPaddingLeft = css`
  padding-left:0;
`
